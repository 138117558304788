import React from 'react'
import { graphql } from 'gatsby'
import config from '../../utils/siteConfig'
import Layout from '../../components/Layout'
import Preisrechner from '../../components/Preisrechner'
import PageBody from '../../components/PageBody'
import GreenLine from '../../components/GreenLine'
import SEO from '../../components/SEO'
import TextContainer from '../../components/TextContainer'
import '../../styles/preisrechner.css'
import '../../styles/perimeter.css'
import ScriptTag from 'react-script-tag';
import { withPrefix, Link } from "gatsby"

import Done from '../../components/Preisrechner/Done'

const Kontakt = ({ data }) => {
  const postNode = {
    title: `${data.contentfulPage.title} - ${config.siteTitle}`,
  }

  const { body, title, subtitle, headerImage } = data.contentfulPage;

  return (
    <Layout>

      <SEO postNode={postNode} pagePath="contact" customTitle />
      <GreenLine backgroundColor={'rgba(0,0,0,0)'} />

      <div className='preisrechner vermPrice'>
        <div className="vermContentWrapper">
          <Done/>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "preise/danke" }) {
      id
      title
      subtitle
      headerImage {
        id
        fluid(quality: 100, maxWidth: 1920, resizingBehavior: NO_CHANGE, toFormat: NO_CHANGE) {
          srcWebp
          srcSetWebp
          srcSet
          src
          sizes
          base64
          aspectRatio
          ...GatsbyContentfulFluid
        }
      }
      body {
        json
      }
    }
  }
`

export default Kontakt